import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';

const BlockOwner = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='my-10 flex flex-wrap gap-4 justify-center'>
            <div className='w-[250px] max-w-xs overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800'>
                <img src={rpdata?.stock?.[22]} className='' alt=''/>
                <h3 className='block text-xl font-bold text-gray-800 dark:text-white text-center'>Juan Lezama</h3>
                <h3 className='block text-xl font-bold text-gray-800 dark:text-white text-center'>Project Manager</h3>
            </div>
        </div>
    )
}


export default BlockOwner;